import { EUserGroup } from 'shared/enums/user/userGroup.enum';

export const AVAILABLE_GROUPS_REPORT = [
  EUserGroup.ClientFinContr,
  EUserGroup.ClientAuditor,
  EUserGroup.ClientMpErp,
  EUserGroup.ClientAdmin,
  EUserGroup.ClientBoss,
  EUserGroup.ClientErp,
  EUserGroup.Supplier,
];
